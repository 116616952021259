
import { mapMutations } from 'vuex';
import Field from '@/components/ui/field.vue';
import PrimaryButton from '@/components/ui/primaryButton.vue';

export default {
  components: {
    Field,
    PrimaryButton,
  },

  props: {
    message: {
      type: String,
      default: null,
    },
    defaultemail: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      password: null,
      email: null,
      error: null,
      passError: [],
      mailError: [],
      working: false,
    };
  },

  watch: {
    email() {
      this.mailError = [];
    },
    password() {
      this.passError = [];
    },
  },

  mounted() {
    if (this.defaultemail) {
      this.email = this.defaultemail;
    }
  },

  methods: {
    ...mapMutations([
      'setUser',
      'setToken',
      'setPatientId',
      'setDeliveryAddress',
    ]),
    closeLogin() {
      this.$emit('close');
    },
    toReset() {
      this.closeLogin();
      this.$router.push(this.localePath({ name: 'reset' }));
    },
    login() {
      this.working = true;
      this.passError = [];
      this.mailError = [];
      if (this.email === null || !/(.+)@(.+){2,}\.(.+){2,}/.test(this.email)) {
        this.mailError.push(this.$t('forms.invalidMail'));
        this.working = false;
        return false;
      }
      if (this.password === null) {
        this.passError.push(this.$t('forms.invalidPassword'));
        this.working = false;
        return false;
      }
      this.error = null;
      this.$bolev
        .login({
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          this.setToken(res.data.access_token);
          this.$bolev
            .user()
            .then((res) => {
              if (res.data) {
                this.setUser(res.data);
                this.setPatientId(res.data.patientId);
                this.setDeliveryAddress(res.data.deliveryAddress);
                this.$router.push(this.localePath({ name: 'shop' }));
              } else {
                this.error = this.$t('global.error');
              }
              this.working = false;
            })
            .catch(() => {
              this.setToken(null);
              this.error = this.$t('global.error');
              this.working = false;
            });
        })
        .catch((err) => {
          if (err.response && err.response.status < 500) {
            this.passError.push(this.$t('login.invalidCredentials'));
            this.mailError.push(this.$t('login.invalidCredentials'));
          } else {
            this.error = this.$t('global.error');
          }
          this.working = false;
        });
    },
  },
};
